/* Base styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
  font: 14px/1.4 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0edfc;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}

.logo {
  height: 50px;
}


/* Hero styles */
.hero {
  padding: 10px 0 50px;
}

.hero .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;
}

.hero h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  margin-top: 50px;
}

.hero p.hero_subtitle {
  font-size: 18px;
  opacity: 0.7;
  line-height: 1.7;
  margin-bottom: 70px;
}

.hero-image-container {
  position: relative;
  height: 400px;
  width: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0;
  margin-left: auto;
}

.hero-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scaleX(-1);
}

.payment-card {
  position: absolute;
  top: 25%;
  right: 20%;
  width: 350px;
  border: 1px solid rgba(99, 91, 255, 0.1);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.02);
  padding: 10px;
  line-height: 1.7;
  animation: move 3s ease-in-out infinite;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.payment-card h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}

.payment-card div:first-child {
  border-radius: 10px;
  background-color: #f5f7f9;
  padding: 20px 30px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  line-height: 1.9;
}

.payment-card div:first-child ion-icon {
  font-size: 20px;
  color: gray;
  margin-right: 10px;
  font-size: 100px;
  position: absolute;
  top: -30px;
  right: -30px;
  transform: rotate(-40deg);
  opacity: 0.1;
}

.payment-card div:first-child p {
  font-size: 13px;
  opacity: 0.5;
}

.payment-card div:nth-child(2) {
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 30px 10px;
}

.payment-card div:nth-child(2) p {
    margin-bottom: 10px;
    line-height: 2;
}

.payment-card div:nth-child(2) p.transfer span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: lightgrey;
  border-radius: 50%;
  margin-right: 5px;
}

.payment-card div:nth-child(2) p.later {
    border: 1px solid #f88a0385;
    padding: 10px 20px;
    border-radius: 8px;
}

p.later i {
  font-size: 12px;
  color: gray;
  opacity: 0.7;
  display: block;
  margin-bottom: 10px;
  font-style: normal;
  margin-top: -5px;
}

.payment-card div:nth-child(2) p.later p {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.payment-card div:nth-child(2) p.later span {   
  border: 1px solid rgba(99, 91, 255, 0.1);
  border-radius: 20px;
  font-size: 12px;
  color: gray;
  padding: 1px 10px;
  width: 50%;
  display: inline-block;
  text-align: center;
}

.payment-card div:nth-child(2) p.later span._active {
    border-color: #f88a0385;
}

.payment-card div:nth-child(2) p.continue {
    font-size: 13px;
    color: #fff;
    text-align: center;
    margin-top: 20px;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f88a03fc;
}

.integrations p {
  opacity: 0.5;
  font-size: 13px;
  margin-bottom: 10px;
}

.integrations img {
  height: 35px;
  margin-right: 10px;
}


/* Features styles */
.features {
    padding: 100px 0;
    background-color: #fff;
}

.subtitle {
  font-size: 13px;
  text-transform: uppercase;
  color: #635BFF;
  font-weight: 500;
  display: block;
  margin-bottom: 10px;
}

.features h2 {
  margin-bottom: 40px;
  font-size: 32px;
  font-weight: 500;
  width: 65%;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px;
}

.feature {
    line-height: 1.8;
    border: 1px solid rgba(99, 91, 255, 0.1);
    border-radius: 10px;
    padding: 40px 30px;
    padding-top: 110px;
    position: relative;
    overflow: hidden;
    font-size: 15px;
}

.feature ion-icon {
    font-size: 100px;
    color: #635BFF;
    position: absolute;
    top: -20px;
    right: -20px;
    opacity: 0.1;
    transform: rotate(-30deg);
}

.feature h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #635BFF;
}

/* Updated Footer styles */
.footer {
  background-color: #635BFF;
  border-top: 25px solid #5046ff;
  color: white;
  padding: 60px 0 30px;
}

.footer-links {
  font-size: 20px;
}

.footer .container {
  display: flex;
  justify-content: space-between;
}

.footer-content {
  margin-bottom: 70px;
  width: 60%;
}

.footer-content h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: lowercase;
}

.footer-content p {
    opacity: 0.7;
    font-size: 18px;
}

.footer-content img {
    height: 50px;
    display: block;
    margin-bottom: 10px;
}

.copyright {
    opacity: 0.7;
    font-size: 13px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
}
